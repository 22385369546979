import React from "react";

import pageStyle from "../assets/css/page.module.css";
import style from "../assets/css/main.module.css";
import Menu from "../components/menu";
import Footer from "../components/footer";
import HeroPage from "../components/hero.page";
import Hamburger from "../components/hamburger";

import SEO from "../components/seo";

import imgHero from "../assets/img/heroes/vie_privee.jpg";


export default () => (
  <div id="top">
    <Menu />
    <SEO
    title="Politique de vie privée | Agence Digitale | MDS Digital Agency"
    description="Grâce à cette déclaration de confidentialité, nous vous informons de la manière dont nous, MDS Digital Agency, traitons et utilisons vos données personnelles."
    keywords="vie privée"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px'}}>
    <h2>Politique de confidentialité</h2>
    <p>
    Le respect de votre vie privée est primordial pour MDS Digital Agency.
    </p>

    <p>
    Grâce à cette déclaration de confidentialité, nous vous informons de la manière dont nous, MDS Digital Agency, traitons et utilisons vos données personnelles.
    </p>
    <p>
    La déclaration de confidentialité que vous retrouvez ci-après est d’application pour toutes les relations entre MDS Digital Agency et ses clients aussi bien clients finaux que commerciaux. 
    </p>
    <p>
    MDS Digital Agency travaille conformément à la directive Européenne 2016/679 du 27 avril 2016 relative à la protection de personnes physiques à l’égard du traitement de données personnelles et à la libre circulation de ces données et déclarant le Directive 95/46/CE « Règlement relatif à la protection des données », ainsi que de la législation nationale actuelle et future sur la mise en œuvre de la Directive mentionnée ci-dessus.
    </p>
    <p>
    Veuillez trouver ci-dessous notre déclaration de confidentialité concernant l’utilisation de notre Site Web MDS Digital Agency et de notre politique en matière de l’utilisation des Cookies.
    </p>
    <span className={pageStyle.privacy_subtitle}>
        1. Types de données personnelles
    </span>
    <p>
    MDS Digital Agency collecte les données personnelles suivantes : 
    </p>
    <ul>
        <li>Nom</li>
        <li>Prénom</li>
        <li>
            Adresse mail
        </li>
        <li>Adresse</li>
        <li>Numero de téléphone
        </li>
    </ul>
    <span className={pageStyle.privacy_subtitle}>
        2. Statistiques de navigation
    </span>
    <p>
    MDS Digital Agency collecte les informations suivantes :
    
    </p>
    <ul>
        <li>Caractéristiques techniques</li>
    </ul>
    <span className={pageStyle.privacy_subtitle}>
        3. Stockage de données sur votre ordinateur via l'utilisation de cookies
    </span>
    <p>
    Les cookies essentiels permettent des fonctionnalités de base, à savoir la navigation dans les pages et l'accès à des zones sécurisées. Le site Web MDS Digital Agency ne peut pas fonctionner convenablement sans ces cookies.
 Ceux-ci ne peuvent être désactivé uniquement en modifiant les préférences de votre navigateur.
    </p>

    </article>

    <Footer />
  </div>
);
